import "core-js/modules/es.string.link.js";
export default {
  props: {
    dataSource: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    loading: {
      type: Boolean,
      default: false
    },
    onTableChange: {
      type: Function,
      default: function _default() {}
    },
    onPageSizeChange: {
      type: Function,
      default: function _default() {}
    },
    onShowSizeChange: {
      type: Function,
      default: function _default() {}
    }
  },
  data: function data() {
    return {
      visible: false,
      columns: [{
        title: "id",
        dataIndex: "id",
        key: "id",
        width: 120
      }, {
        title: "图片",
        dataIndex: "picurl",
        key: "picurl",
        width: 120,
        slots: {
          title: "picurl"
        },
        scopedSlots: {
          customRender: "picurl"
        }
      }, {
        title: "跳转类型",
        dataIndex: "contenttype",
        key: "contenttype",
        width: 200,
        slots: {
          title: "contenttype"
        },
        scopedSlots: {
          customRender: "contenttype"
        }
      }, {
        title: "跳转链接",
        dataIndex: "linkurl",
        key: "linkurl",
        width: 200,
        slots: {
          title: "linkurl"
        },
        scopedSlots: {
          customRender: "linkurl"
        }
      }, {
        title: "状态",
        dataIndex: "istakeeffect",
        key: "istakeeffect",
        width: 80,
        slots: {
          title: "istakeeffect"
        },
        scopedSlots: {
          customRender: "istakeeffect"
        }
      }, {
        title: "操作",
        key: "action",
        textAlign: "center",
        fixed: "right",
        width: 140,
        slots: {
          title: "action"
        },
        scopedSlots: {
          customRender: "action"
        }
      }],
      link: ""
    };
  },
  methods: {
    handleEdit: function handleEdit(id) {
      this.$emit("edit", id);
    },
    handleDel: function handleDel(id) {
      this.$emit("delete", id);
    },
    handlePreview: function handlePreview(url) {
      this.link = url;
      this.visible = true;
    }
  }
};