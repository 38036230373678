import "core-js/modules/es.string.link.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "table-wrapper"
  }, [_c("a-table", {
    attrs: {
      pagination: false,
      "data-source": _vm.dataSource,
      columns: _vm.columns,
      loading: _vm.loading,
      rowKey: "id"
    },
    on: {
      change: _vm.onTableChange
    },
    scopedSlots: _vm._u([{
      key: "picurl",
      fn: function fn(picurl) {
        return [_c("img", {
          staticClass: "cover",
          attrs: {
            src: picurl,
            alt: ""
          },
          on: {
            click: function click($event) {
              return _vm.handlePreview(picurl);
            }
          }
        })];
      }
    }, {
      key: "linkurl",
      fn: function fn(linkurl) {
        return [_c("a-button", {
          staticClass: "btn-link",
          attrs: {
            type: "link"
          }
        }, [_c("a", {
          attrs: {
            href: linkurl,
            target: "_blank",
            rel: "noopener noreferrer"
          }
        }, [_vm._v(_vm._s(linkurl))])])];
      }
    }, {
      key: "contenttype",
      fn: function fn(contenttype) {
        return [_c("span", [_vm._v(_vm._s(contenttype == 0 ? "H5" : contenttype == 1 ? "视频" : "合集"))])];
      }
    }, {
      key: "istakeeffect",
      fn: function fn(istakeeffect) {
        return [istakeeffect == 0 ? _c("span", [_vm._v("未生效")]) : _c("span", [_vm._v("已生效")])];
      }
    }, {
      key: "action",
      fn: function fn(_ref) {
        var id = _ref.id;
        return [_c("div", {
          staticClass: "btn-actions"
        }, [_c("a-button", {
          attrs: {
            type: "link"
          },
          on: {
            click: function click($event) {
              return _vm.handleEdit(id);
            }
          }
        }, [_vm._v("编辑")]), _c("a-button", {
          staticStyle: {
            color: "#ff4d4f"
          },
          attrs: {
            type: "link"
          },
          on: {
            click: function click($event) {
              return _vm.handleDel(id);
            }
          }
        }, [_vm._v("删除")])], 1)];
      }
    }])
  }), _c("PreviewPic", {
    attrs: {
      isShowPic: _vm.visible,
      previewImage: _vm.link
    },
    on: {
      closePreviewpic: function closePreviewpic() {
        return _vm.visible = false;
      }
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };